import {computed} from 'vue';
import type {Browser} from 'detect-browser';
import {detect} from 'detect-browser';

type BrowserName = Browser | 'bot' | 'node' | 'react-native' | undefined;

/**
 * Expose the navigator name and version after it was detecter by detect-browser
 *
 * ```ts
 * useBrowserVersion();
 * ```
 */
export function useBrowserDetection() {
    const browser = detect();

    /**
     * The browser name or undefined if it can not be detected
     */
    const name = computed<BrowserName>(() => browser?.name);

    /**
     * The browser version or undefined if it can not be detected
     */
    const version = computed<string | undefined>(() => browser?.version ?? undefined);

    const majorVersion = computed<number>(() => extractFromVersionAndPosition(version.value, 0));
    const minorVersion = computed<number>(() => extractFromVersionAndPosition(version.value, 1));
    const patchVersion = computed<number>(() => extractFromVersionAndPosition(version.value, 2));

    function extractFromVersionAndPosition(version: string | undefined, position: 0 | 1 | 2) {
        const splittedVersion = version?.split('.');
        if (splittedVersion && splittedVersion[position]) {
            try {
                const versionAsNumber = parseInt(splittedVersion[position]);
                return versionAsNumber;
            } catch {
                return 0;
            }
        }
        return 0;
    }

    /**
     * It detects the current version of the browser and say if the semver is inferior with the one in parameter
     *
     * @param version The version we want to compare
     *
     * @return Return true if the current browser version is inferior to the one given
     */
    function isCurrentVersionLessThan(version: string): boolean {
        try {
            const splittedVersion = version.split('.').map(Number);
            const [majorCompared, minorCompared, patchCompared] = splittedVersion;

            if (majorCompared !== undefined && majorVersion.value < majorCompared) {
                return true;
            }
            if (
                majorCompared !== undefined &&
                minorCompared !== undefined &&
                majorVersion.value === majorCompared &&
                minorVersion.value < minorCompared
            ) {
                return true;
            }
            if (
                majorCompared !== undefined &&
                minorCompared !== undefined &&
                patchCompared !== undefined &&
                majorVersion.value === majorCompared &&
                minorVersion.value === minorCompared &&
                patchVersion.value < patchCompared
            ) {
                return true;
            }

            return false;
        } catch {
            return false;
        }
    }

    return {
        name,
        version,
        majorVersion,
        minorVersion,
        patchVersion,
        isCurrentVersionLessThan,
    };
}
