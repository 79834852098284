import {useLocale} from './useLocale';
import {useLogger} from '#imports';
import {useBrowserDetection} from '@malt/browser-detection';

export const useFormat = () => {
    const {locale} = useLocale();
    const logger = useLogger();
    const {name, isCurrentVersionLessThan} = useBrowserDetection();

    /**
     * Format a date using the current locale (detected with i18next)
     *
     * @param {(string|Date|null|undefined)} dateInput - A Date object or a string
     * @param {Intl.DateTimeFormatOptions} options - Options for formatting
     * @returns {string} Return formatted date.
     *
     * Example:
     *
     * - 1 juin 2022 (in french)
     * - 1 June 2022 (in english)
     */
    function formatDate(dateInput: Date | string | null | undefined, options: Intl.DateTimeFormatOptions = {dateStyle: 'long'}): string {
        try {
            if (!dateInput) {
                return '';
            }
            const dateToFormat = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
            return new Intl.DateTimeFormat(locale.value, options).format(dateToFormat);
        } catch (e) {
            logger.error('Invalid time value');
            return '';
        }
    }

    /**
     * Format a date string using the current locale (detected with i18next)
     *
     * @param {(string|Date|null|undefined)} dateInput - A Date object or a string
     * @param {Intl.DateTimeFormatOptions} options - Options for formatting
     * @returns {string} Return formatted date in short date style
     *
     * Example:
     *
     * - 11/10/2022 (in french)
     * - 10/11/2022 (in english)
     */
    function formatDateToShort(
        dateInput: string | Date | undefined | null,
        options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        },
    ): string {
        return formatDate(dateInput, options);
    }

    /**
     * Format a date string using the current locale (detected with i18next)
     *
     * @param {(string|Date|null|undefined)} dateInput - A Date object or a string
     * @param {Intl.DateTimeFormatOptions} options - Options for formatting
     * @returns {string} Return formatted date in short date style with hours
     *
     * Example:
     *
     * - 11/10/2022 08:23:24 (in french)
     * - 10/11/2022 08:23:24 (in english)
     */
    function formatDateToShortWithHours(
        dateInput: string | Date | undefined | null,
        options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false,
        },
    ): string {
        return formatDate(dateInput, options);
    }

    function getCurrencyOptions(currencyCode?: string, currencyOptions = {decimals: 0}): Intl.NumberFormatOptions {
        if (!currencyCode) {
            logger.warn('currencyCode should not be undefined');
        }

        /**
         * Safari less than 14.1.0 does not support currencyDisplay to 'narrowSymbol' so we fallback to 'symbol'
         */
        if (name.value === 'safari' && isCurrentVersionLessThan('14.1.0')) {
            return currencyCode
                ? {
                      style: 'currency',
                      currency: currencyCode,
                      currencyDisplay: 'symbol',
                      minimumFractionDigits: currencyOptions.decimals,
                      maximumFractionDigits: currencyOptions.decimals,
                  }
                : {};
        }

        return currencyCode
            ? {
                  style: 'currency',
                  currency: currencyCode,
                  currencyDisplay: 'narrowSymbol',
                  minimumFractionDigits: currencyOptions.decimals,
                  maximumFractionDigits: currencyOptions.decimals,
              }
            : {};
    }

    /**
     * Format an amount using the current locale (detected with i18next)
     *
     * @param {string} currencyCode
     * @param {number} amount
     * @param {*} options - Options for formatting
     * @returns {string} Return formatted amount
     *
     */
    function formatAmount(currencyCode: string | undefined, amount: number, currencyOptions = {decimals: 0}): string {
        const options = getCurrencyOptions(currencyCode, currencyOptions);
        return new Intl.NumberFormat(locale.value, options).format(amount);
    }

    /**
     * Get the currency symbol from a currency code and using current locale (detected with i18next)
     *
     * @param {string} currencyCode
     * @returns {string}
     *
     */
    function getCurrencySymbolFrom(currencyCode: string | undefined): string {
        const options = getCurrencyOptions(currencyCode);
        const symbol = new Intl.NumberFormat(locale.value, options).formatToParts(1).find((x) => x.type === 'currency');
        if (symbol === undefined) {
            return '';
        }
        return symbol?.value;
    }

    function formatPercent(percentage: number, options: Intl.NumberFormatOptions = {maximumFractionDigits: 2}): string {
        return (percentage / 100).toLocaleString(locale.value, {
            style: 'percent',
            ...options,
        });
    }

    return {
        formatDate,
        formatDateToShort,
        formatDateToShortWithHours,
        formatAmount,
        formatPercent,
        getCurrencySymbolFrom,
    };
};
